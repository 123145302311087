import React, { useState, useEffect, useRef, memo } from 'react';

import './Outages.css'

import OutageRow from './OutageRow';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import outagesComponentToShowState, { outagesListTicketState, outagesListTickeClosedtState } from '../../atoms/outages_state.atom';


import { api_bd, api_bd_nr } from '../../services/Api'

var qtd_closed_events = ''
function TicketList(props) {
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value; //assign the value of ref to the argument
        }, [value]); //this code will run when the value of 'value' changes
        return ref.current; //in the end, return the current ref value.
    }


    const [eventList, setEventList] = useRecoilState(outagesListTicketState)
    const [eventListClosed, setEventListClosed] = useRecoilState(outagesListTickeClosedtState)
    const [qtdClosedEvents, setQtdClosedEvents] = useState('30')
    const prevList = usePrevious(eventList)
    const profile_user = useRecoilValue(profileData)
    const componentToShow = useRecoilValue(outagesComponentToShowState)
    const [listSelectEventsMode, setListSelectEventsMode] = useState('lastEvents')
    const [dateToListEvents, setDateToListEvents] = useState(new Date().toLocaleDateString('en-ca'))//useState('lastEvents')

    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/outages/status/aberto`)
        if (tmpEv.data.length === 0) {
            // setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
            setEventList([])
        } else {
            if (componentToShow === null) {
                setEventList(tmpEv.data)
            }
        }
    }

    const buscarEventosFechados = async (_qtd) => {
        let tmpEv = await api_bd.get(`/outages/status/fechado/${_qtd}`)
        if (tmpEv.data.length === 0) {
            // setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
        } else {
            if (componentToShow === null) {
                setEventListClosed(tmpEv.data)
            }
        }
    }

    const buscarEventosFechadosPorData = async (_date) => {
        let tmpEv = await api_bd_nr.get(`/outages/status/fechadodata/${_date}`)
        console.log('tmpEv.data', tmpEv.data)
        if (tmpEv.data.length === 0) {
            // setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
        } else {
            if (componentToShow === null) {
                setEventListClosed(tmpEv.data)
            }
        }
    }

    const falar = (_msg) => {
        const msg = new SpeechSynthesisUtterance()
        msg.text = _msg
        window.speechSynthesis.speak(msg)
    }


    const triggerNovos = () => {
        // if(eventList.length > 0){
        //     console.log('outros fetchs')
        // }else{
        //     console.log('primeiro fetch')
        // }
        // console.log(eventList)
        // console.log('prev: ', prevList, '\nevent:', eventList)
        if ((prevList) && (eventList.length > prevList?.length)) {
            // console.log('prev: ', prevList?.length, ' - actual: ', eventList.length)

            let novos = eventList.filter(evt => {
                return prevList.find(prevEv => {
                    return (prevEv.cod_id === evt.cod_id)
                }) ? false : true
            });

            if (prevList.length === 0) {
                novos = eventList
            }

            for (let k in novos) {
                let tipoEvento = novos[k].tipo_evento.toLowerCase()
                switch (tipoEvento) {
                    case 'link com problema':
                        if (novos[k].qual_o_problema.toLowerCase() === 'rompimento') {
                            // falar(`Atenção, novo evento cadastrado, ${tipoEvento}, ${novos[k].qual_o_problema.toLowerCase()}, entre, ${novos[k].trecho_ponto_a.toLowerCase()}, e , ${novos[k].trecho_ponto_b.toLowerCase()}, número do evento, ${novos[k].cod_id}`)
                        } else {
                            // falar(`Atenção, novo evento cadastrado, ${tipoEvento}, ${novos[k].qual_o_problema.toLowerCase()}, fornecedor, ${novos[k].fornecedor.toLowerCase()}, número do evento, ${novos[k].cod_id}`)
                        }
                        break;
                    case 'falha elétrica':
                        // falar(`Atenção, novo evento cadastrado, ${tipoEvento}, estação, ${novos[k].estacao.toLowerCase()}, equipamento, ${novos[k].equipamento.toLowerCase()}, número do evento, ${novos[k].cod_id}`)
                        break;
                    case 'estação indisponível':
                        // falar(`Atenção, novo evento cadastrado, ${tipoEvento}, estação, ${novos[k].estacao.toLowerCase()}, número do evento, ${novos[k].cod_id}`)
                        break;

                    default:
                        // falar(`Atenção, novo evento cadastrado, ${tipoEvento}, número do evento, ${novos[k].cod_id}`)
                        break;
                }

            }

            // console.log('novos: ', novos)

        }
    }


    useEffect(() => {
        buscarEventos()
        // buscarEventosFechados()
        let interval = setInterval(() => {
            buscarEventos()
            buscarEventosFechados(qtd_closed_events)
        }, 30000)
        return () => {
            clearInterval(interval)
        }
    }, [])


    useEffect(() => {
        triggerNovos()
    }, [eventList])


    useEffect(() => {
        if (listSelectEventsMode === 'lastEvents') {
            qtd_closed_events = qtdClosedEvents
            buscarEventosFechados(qtd_closed_events)
        } else if (listSelectEventsMode === 'date') {
            buscarEventosFechadosPorData(dateToListEvents)
        }
    }, [qtdClosedEvents, listSelectEventsMode, dateToListEvents])




    const styleDivContainerOutageRow = {flexDirection: 'row', flexWrap: 'wrap'}

    return (
        <div className='card' style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '5%', alignItems: 'flex-start' }}>

            {/* Content */}

            <div style={{ display: 'flex', flexDirection: 'column', width: (props?.tvView === 'tv') ? '100%' : '53%', height: '100%' }}>
                <span style={{ fontSize: '1.8em', fontWeight: 'bold', paddingTop: 13, paddingBottom: 30 }}>Eventos em andamento</span>
                <div className='outage-rows-container' style={(props?.tvView === 'tv') ? styleDivContainerOutageRow : {marginTop: 44} }>
                    {eventList.length > 0 && eventList.map(ev => (
                        !ev.evento_solucionado?.status &&
                        <OutageRow ev={ev} key={`${ev._id}`} buscar_eventos={buscarEventos} tvView={props.tvView} />
                    ))}

                    {eventList.length === 0 &&
                        <div style={{ fontSize: '1.5em', display: 'flex', width: '100%', justifyContent: 'center' }}>Nenhum evento aberto</div>
                    }
                </div>
            </div>


            {/* Historico de eventos */}
            {props.tvView === 'desktop' &&
                <div style={{ display: 'flex', flexDirection: 'column', width: '40%', height: '100%' }}>
                    <div style={{ marginTop: 13, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>Listagem de eventos: </span>
                        <select style={{ marginLeft: 10, fontSize: '1.6em', fontWeight: 'bold', color: '#324d8c' }}
                            onChange={(e) => {
                                setListSelectEventsMode(e.target.value)
                            }}
                            value={listSelectEventsMode}
                        >
                            <option value="date">Data</option>
                            <option value="lastEvents">Últimos</option>
                        </select>
                    </div>

                    {listSelectEventsMode === 'lastEvents' &&
                        <div style={{ marginTop: 0, marginBottom: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>Últimos Eventos Finalizados</span>
                            <span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>Qtd:</span>
                            <select style={{ marginLeft: 10, fontSize: '1.6em', fontWeight: 'bold', color: '#324d8c' }}
                                onChange={(e) => {
                                    setQtdClosedEvents(e.target.value)
                                }}
                                value={qtdClosedEvents}
                            >
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="todos">Todos</option>
                            </select>
                        </div>
                    }
                    {listSelectEventsMode === 'date' &&
                        <div style={{ marginTop: 0, marginBottom: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>Eventos Finalizados em: </span>
                            <input
                                style={{ marginLeft: 5, fontSize: '1.6em', fontWeight: 'bold' }}
                                onChange={(e) => {
                                    setDateToListEvents(e.target.value)
                                }}
                                type="date"
                                value={dateToListEvents}
                            />
                        </div>
                    }

                    <div className="outage-rows-container">
                        {eventListClosed.length > 0 && eventListClosed.map(evc => (
                            <OutageRow ev={evc} key={`${evc._id}`} buscar_eventos={buscarEventosFechados} />
                        ))}

                        {eventListClosed.length === 0 &&
                            <div style={{
                                fontSize: '1.5em',
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                Nenhum evento finalizado
                            </div>
                        }
                    </div>
                </div>
            }



        </div>
    )
}

export default TicketList;