import React, { useState, useEffect, useRef } from 'react';

import {
    Link,
    useHistory
} from "react-router-dom";

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import * as IoIcons from 'react-icons/io';
import EditIcon from '@material-ui/icons/Edit';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import GDriveIcon from '../assets/drive_logo.svg';

import Styles, { Fab, MiniFab, Btn, colors_access_types, primary_color } from '../components/Styles'

import {
    api_bd, api_bd_nr,
    // getUserData 
} from '../services/Api'

import FormEditClient from '../components/Forms/FormEditClient'
import FormDataTransit from '../components/Forms/FormDataTransit'
import FormDataPublicIp from '../components/Forms/FormDataPublicIp'
import FormDataAccess from '../components/Forms/FormDataAccess'
import FormEditAccess from '../components/Forms/FormEditAccess'
import FormDataSenhas from '../components/Forms/FormDataSenhas'
import FormDataObservacoes from '../components/Forms/FormDataObservacoes'
import FormDataDefaults from '../components/Forms/FormDataDefaults'
import FormDataStation from '../components/Forms/FormDataStation'

import ListAccessByType from '../components/ListsAccessByType'

import Autocomplete from '@material-ui/lab/Autocomplete';


import { useRecoilState, useRecoilValue } from 'recoil'
import userState, { profileData } from '../atoms/user.atom'
import clientDetailsData, { listAccess } from '../atoms/client_details.atom'
import Modal from '../components/Modal'

import HidePass from '../util/fns'


const { backend_files_address, backend_svg_files } = require('../config.json')




const ShowTimeContract = ({ customerData }) => {
    const [report, setReport] = useState({ nome: '', horasContrato: 0, horasContratoMs: 0, tempoTotal: 0, tempoTotalF: '00:00', analistas: {}, month: '', year: 0 })
    const [classColor, setClassColor] = useState('#fff')

    useEffect(() => {
        if (customerData._id) {
            api_bd_nr.get(`/getcustomertime?id=${customerData._id}`).then(resp => {
                if (resp?.data?.nome) {


                    const contratoMS = (resp.data.horasContrato * 3600000)
                    const percentUtilization = resp.data.horasContrato === 0 ? 0 : (resp.data.tempoTotal / contratoMS) * 100

                    if (percentUtilization <= 60) {
                        setClassColor('#0ffc03')
                    } else if (percentUtilization > 60 && percentUtilization <= 100) {
                        setClassColor('orange')
                    } else if (percentUtilization > 100) {
                        setClassColor('#ff0000')
                    }

                    setReport(resp.data)
                }
            }).catch(error => {
                // console.log(error)
            })
        }
    }, [])

    return (
        <div style={{ width: "100%", fontSize: "1.1em", color: classColor }} className="flex center-h font-bold">
            {classColor === '#0ffc03' && <span style={{ marginLeft: 5, marginRight: 5 }}>✅</span>}
            {classColor === 'orange' && <span style={{ marginLeft: 5, marginRight: 5 }}>⚠️</span>}
            {classColor === '#ff0000' && <span style={{ marginLeft: 5, marginRight: 5 }}>🟥</span>}
            {report.tempoTotal === 0 ? '00:00' : report.tempoTotalF} / {report.horasContrato === 0 ? '00:00' : report.horasContrato + ':00'}
        </div>
    )

}


const ExportToTermiusBtn = ({ data_client, userLevel = '' }) => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        userLevel.match(/super/i) && <Btn onClick={() => {
            setIsLoading(true)
            console.log('get', `/exporttermius/${data_client._id}`)
            api_bd_nr.get(`/exporttermius/${data_client._id}`).then(response => {
                setIsLoading(false)
                if (response?.data.length > 10) {
                    const blob = new Blob([response.data], { type: 'text/csv' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, 'export-to-termius.csv');
                    }
                    else {
                        const elem = window.document.createElement('a');
                        elem.href = window.URL.createObjectURL(blob);
                        elem.download = 'export-to-termius.csv';
                        document.body.appendChild(elem);
                        elem.click();
                        document.body.removeChild(elem);
                    }
                }
            }).catch(e => {
                console.log('ExportToTermiusBtn ERROR ', e)
                setIsLoading(false)
            })
        }}
            style={{ width: '100%', maxWidth: 220, height: 35, marginTop: 5, textAlign: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            {isLoading === false && <span className='ml-0 text-[0.8em] truncate overflow-hidden'>Exportar acessos para o termius</span>}
            {isLoading && <span className='ml-0 text-[0.8em] truncate overflow-hidden'>Exportando, aguarde.....</span>}
        </Btn>
    )
}


function ClientDetails(props) {
    const styles = Styles();
    // const [data_client, set_data_client] = useState(props.location.state.data_client)
    const [data_client, set_data_client] = useRecoilState(clientDetailsData)
    const lista_acessos = useRecoilValue(listAccess)

    // const [glpi_user_data, set_glpi_user_data] = useState({})


    // const [sgi_user, set_sgi_user] = useRecoilState(userState)

    const profile_data = useRecoilValue(profileData)


    const [glpi_user_name, set_glpi_user_name] = useState('')
    const [glpi_user, set_glpi_user] = useState('')
    const [glpi_level, set_glpi_level] = useState('')

    const [show_modal, set_show_modal] = useState(false)
    const [content_modal, set_content_modal] = useState(null)
    const [document_height, set_document_height] = useState(null)
    const [show_edit_button, set_show_edit_button] = useState(false)

    const [show_senhas, set_show_senhas] = useState(false)
    const [show_transitos, set_show_transitos] = useState(false)
    const [show_ipspublicos, set_show_ipspublicos] = useState(false)
    const [show_defaults, set_show_defaults] = useState(false)


    // const [before_filter_data_access, set_before_filter_data_access] = useState([])
    const [data_access, set_data_access] = useState([])

    const [data_stations, set_data_stations] = useState([])

    const [type_filter_access, set_type_filter_access] = useState('Todos')
    const [filter_access, set_filter_access] = useState('')
    const [access_to_edit, set_access_to_edit] = useState({})

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const [qtd_type_vpn, set_qtd_type_vpn] = useState(0)
    const [qtd_type_conta, set_qtd_type_conta] = useState(0)
    const [qtd_type_servidor, set_qtd_type_servidor] = useState(0)
    const [qtd_type_ferramenta, set_qtd_type_ferramenta] = useState(0)
    const [qtd_type_ativo, set_qtd_type_ativo] = useState(0)

    const [showMenu, setShowMenu] = useState(true)

    // const [show_menu_left, set_show_menu_left] = useState(false)

    const menu_lateral_ref = useRef(null);

    const set_show_menu_left = () => {
        // menu_lateral_ref.classList.toggle("mystyle");
        menu_lateral_ref.current.classList.toggle('menu_close')
    }


    let history = useHistory();

    const style_modal = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        backgroundColor: '#00000055',
        left: 0,
        top: 0,
        position: 'absolute',
        float: 'left',
        minWidth: '100%',
        maxWidth: '100%',
        height: `${device_height}px`,
        maxHeight: `${device_height}px`,
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: "auto"

    }

    // const handleOpen = () => {
    //     set_show_modal(true);
    // };

    const handleClose = () => {
        set_show_modal(false);
    };


    const carregar_estacoes = async (_customerId) => {
        const lista = await api_bd.get(`/stations/${_customerId}`)
        return lista.data
    }



    const data_client_update = async (new_data) => {
        // set_data_client(new_data)
        // set_data_access(new_data.acessos)
        handleClose()
    }

    useEffect(() => {
        const body = document.body, html = document.documentElement;
        const total_height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        set_document_height(total_height)
    }, [show_modal])


    useEffect(() => {

        // if (Object.keys(data_client).length === 0) {
        //     props.history.push("/")
        // }


        (async () => {
            const windo_pathname = window.location.pathname.split('/')

            // console.log('pathname: ', windo_pathname)

            //clientdetails
            if (windo_pathname[2] && windo_pathname[2] !== '') {
                console.log('Cliente: ', windo_pathname[2])

                const dados = await api_bd.get(`customers/getdatafui/${windo_pathname[2]}`)
                set_data_client(dados.data[0])
            }
        })()


        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)

        // var start = null;
        // var swipe_start = null;
        // var swipe_end = null;

        // const swipe_duration = 300
        // window.addEventListener("touchstart", function (event) {
        //     if (event.touches.length === 1) {
        //         //just one finger touched
        //         start = event.touches.item(0).clientX;
        //         swipe_start = new Date().getTime()
        //         //console.log(start)
        //     } else {
        //         //a second finger hit the screen, abort the touch
        //         start = null;
        //         swipe_start = null
        //     }
        // });

        // window.addEventListener("touchend", function (event) {
        //     var offset = 100;//at least 100px are a swipe
        //     if (start) {
        //         //the only finger that hit the screen left it
        //         var end = event.changedTouches.item(0).clientX;
        //         swipe_end = new Date().getTime()

        //         console.log(swipe_start, swipe_end, swipe_end - swipe_start, swipe_duration)
        //         //console.log(end)
        //         if ((end > start + offset) && ((swipe_end - swipe_start) < swipe_duration)) {
        //             //a left -> right swipe
        //             set_show_menu_left(true)
        //         }

        //         if ((end < start - offset) && ((swipe_end - swipe_start) < swipe_duration)) {
        //             //a right -> left swipe
        //             set_show_menu_left(false)
        //         }
        //     }
        // });

        return function cleanup() {
            // window.addEventListener("touchstart", function (event) { })
            // window.addEventListener("touchend", function (event) { })
        }
    }, [])

    useEffect(() => {

        if (data_client && Object.keys(data_client).length > 0) {

            (async () => {
                const lista_estacoes = await carregar_estacoes(data_client._id)
                const ordered_list = lista_estacoes.sort((a, b) => a.codigo_estacao.localeCompare(b.codigo_estacao))
                set_data_stations(ordered_list)
            })()

            const acessos = [...data_client.acessos]
            // const data = data_client.acessos.length > 0 ? await data_client.acessos.sort((a, b) => a.descricao.localeCompare(b.descricao)) : []
            const data = acessos.sort((a, b) => a.descricao.localeCompare(b.descricao))
            set_data_access(data)

            set_glpi_user((profile_data.nivel.match(/Técnico/i) || profile_data.nivel.match(/Super/i)) ? data_client.user_glpi : profile_data.username)
            set_glpi_level(profile_data.nivel)
            set_glpi_user_name(profile_data.username)
        }

        if (data_client.acessos && data_client.acessos.length > 0) {
            set_qtd_type_ativo(data_client.acessos.filter(ac => ac.tipo == 'Ativo de Rede').length)
            set_qtd_type_conta(data_client.acessos.filter(ac => ac.tipo == 'Conta').length)
            set_qtd_type_ferramenta(data_client.acessos.filter(ac => ac.tipo == 'Ferramenta').length)
            set_qtd_type_servidor(data_client.acessos.filter(ac => ac.tipo == 'Servidor').length)
            set_qtd_type_vpn(data_client.acessos.filter(ac => ac.tipo == 'VPN').length)
        }

        // set_data_access(data_client.acessos)
    }, [data_client])

    // filtro de acesso pelo campo de localidade
    useEffect(() => {
        if (lista_acessos && lista_acessos.length > 0) {
            (async () => {
                if (filter_access.length == 0) {
                    if (type_filter_access == "Todos") {
                        set_data_access(lista_acessos)
                    } else {
                        const new_data_access = await lista_acessos.filter((ac) => {
                            if (ac.tipo == type_filter_access) {
                                return ac
                            } else {
                                return false
                            }
                        })
                        set_data_access(new_data_access)
                    }
                } else {
                    const regexp = new RegExp(`${filter_access}`, 'i')
                    const new_data_access = await lista_acessos.filter((ac) => {
                        if (type_filter_access == "Todos") {
                            if (ac.estacao.match(regexp)) {
                                return ac
                            } else {
                                return false
                            }
                        } else {
                            if (ac.estacao.match(regexp) && ac.tipo == type_filter_access) {
                                return ac
                            } else {
                                return false
                            }
                        }
                    })
                    set_data_access(new_data_access)
                }

            })()
        }
    }, [filter_access])


    // Filtro de acesso pelo tipo
    useEffect(() => {
        if (lista_acessos && lista_acessos.length > 0) {
            (async () => {
                if (type_filter_access == "Todos") {
                    set_data_access(lista_acessos)
                } else {
                    const new_data_access = await lista_acessos.filter((ac) => {
                        if (ac.tipo == type_filter_access) {
                            return ac
                        } else {
                            return false
                        }
                    })
                    set_data_access(new_data_access)
                }
            })()
        }
    }, [type_filter_access])



    // const HidePass = (props) => {
    //     const { _pass, _txt, _style } = props
    //     return (
    //         <div
    //             style={_style ? _style : {}}
    //             onClick={() => {
    //                 if (_pass) {
    //                     copyToClipBoard(_pass)
    //                 }
    //             }}
    //         >
    //             <b>{_txt ? _txt : ''} </b>{_pass.replaceAll(/[^ ]/g, '*')}
    //         </div>
    //     )
    // }


    // const copyToClipBoard = async (_text, _pop = false) => {
    //     try {
    //         await navigator.clipboard.writeText(_text);
    //         if (_pop) {
    //             alert('Copiado!');
    //         }
    //     } catch (err) {
    //         console.log(err)
    //         alert('Falha ao copiar.');
    //     }
    // };


    return (
        <div className="flex flexRow height_less_header" style={{ width: "100%" }}>
            {show_modal &&
                <Modal callbackfn={handleClose}>
                    {content_modal != null &&
                        (() => {
                            switch (content_modal) {
                                case 'access':
                                    return <FormDataAccess width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'pip':
                                    return <FormDataPublicIp width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'transit':
                                    return <FormDataTransit width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'senhas':
                                    return <FormDataSenhas width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'obs':
                                    return <FormDataObservacoes width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'client':
                                    return <FormEditClient width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'defaults':
                                    return <FormDataDefaults width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'edit_access':
                                    return <FormEditAccess width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} data_access={access_to_edit} />
                                    break;
                                case 'cad_station':
                                    return <FormDataStation width={device_width < device_height ? "80%" : "50%"} set_data_new_station={(e) => { alert(e) }} data_client={data_client} />
                                    break;
                                default:
                                    return <></>
                                    break;
                            }
                        })()
                    }
                </Modal>
            }
            {Object.keys(data_client).length > 0 && (
                <div
                    style={{
                        backgroundColor: "#3c5ca6",
                        padding: 20,
                        minWidth: 210,
                        width: 210,
                        // minHeight: device_height - 50, 
                        // maxHeight: device_height - 50,

                        marginLeft: showMenu ? 0 : -210,
                        transition: 'margin-left 1s',

                        color: "white",
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    // className={device_width <= 1220 ? show_menu_left ? 'menu_open' : 'menu_close' : ''}
                    ref={menu_lateral_ref}
                    className="height_less_header"
                // height_less_header menu_open"
                >
                    <div
                        style={{ top: 12, left: 10, position: 'absolute' }}
                        // className=" ticketsTableMin"
                        onClick={() => {
                            // set_show_menu_left(!show_menu_left)
                            // set_show_menu_left()
                            setShowMenu(!showMenu)
                        }}
                    >
                        <span style={{ cursor: 'pointer', color: '#fff', fontSize: '2.3em' }} className="material-icons">menu</span>
                    </div>
                    <div
                        style={{
                            marginTop: -5,
                            marginLeft: 15,
                            marginRight: 15,
                            marginBottom: 5,
                            overflow: "hidden",
                            minWidth: 140,
                            width: 140,
                            minHeight: 140,
                            height: 140,
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                            border: '3px solid lightgray',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 140, maxWidth: 140, height: 140, maxHeight: 140 }}>
                            <img
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    width: 140,
                                    height: 140,
                                    objectFit: "cover"
                                }}
                                src={data_client.logomarca.filename == "" ? `${backend_files_address}/default-logo.png` : `${backend_files_address}/${data_client.logomarca.filename}`}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            marginLeft: 33,
                            marginTop: -128,
                            width: 105,
                            minWidth: 105,
                            height: 105,
                            minHeight: 105,
                            // backgroundColor: 'yellow',
                            position: 'relative',
                            float: 'left',
                            borderRadius: "50%",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    ></div>

                    {glpi_level.match(/Super|Técnico-Admin/i) &&
                        <div style={{
                            float: 'left',
                            position: 'relative',
                            marginLeft: 20,
                            marginTop: glpi_level === 'Super' ? -15 : -15,
                            minWidth: 30,
                            minHeight: 30
                        }}>

                            <MiniFab
                                style={{
                                    // border: "2px #fff solid",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 999999,
                                    backgroundColor: 'white'
                                }}
                                onClick={() => {
                                    set_content_modal('client')
                                    set_show_modal(true)

                                }}>
                                <span className="material-icons" style={{ marginLeft: 1, color: primary_color }}>
                                    edit
                                </span>
                            </MiniFab>
                        </div>
                    }
                    {(glpi_level.match(/Super|Técnico/i)) &&
                        <div style={{
                            float: 'left',
                            position: 'relative',
                            marginLeft: 122,
                            marginTop: glpi_level.match(/Super/i) ? -29 : -29,
                            minWidth: 30,
                            minHeight: 30
                        }}>

                            <MiniFab
                                style={{
                                    // border: "2px #fff solid",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 999999,
                                    backgroundColor: data_client.observacoes == '' ? 'blue' : 'red'
                                }}
                                onClick={() => {
                                    set_content_modal('obs')
                                    set_show_modal(true)
                                }}>
                                <span className="material-icons" style={{ fontSize: '0.6em', marginLeft: 0 }}>
                                    priority_high
                                </span>
                            </MiniFab>

                        </div>
                    }
                    {/* Fim Dados Cliente (logo, nome, whats, drive) */}


                    <div style={{ width: "100%", marginTop: glpi_level.match(/Cliente/) ? 35 : 20 }} className="flex flexCol center-h">
                        <div style={{ width: "100%", fontSize: "1.1em" }} className="flex center-h">{data_client.nome}</div>

                        <ShowTimeContract customerData={data_client} />

                        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", marginTop: 5 }}>
                            {(glpi_level.match(/Super|Técnico/i)) && data_client.link_whats !== undefined && data_client.link_whats != "" &&
                                <a target="_blank" href={`${data_client.link_whats}`}>
                                    <Btn style={{ width: 60, margin: 5, justifyContent: 'center' }}>
                                        <WhatsAppIcon style={{ fontSize: 25 }} />
                                    </Btn>
                                </a>
                            }


                            {(glpi_level.match(/Super|Técnico/i)) && data_client.link_gdrive !== undefined && data_client.link_gdrive != "" &&
                                <a target="_blank" href={`${data_client.link_gdrive}`}>
                                    <Btn style={{ width: 60, margin: 5, justifyContent: 'center' }}>
                                        <img style={{ width: 25 }} src={GDriveIcon} />
                                    </Btn>
                                </a>
                            }
                        </div>


                        {/* {(glpi_level.match(/Cliente/)) && */}
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", marginTop: 5 }}>
                            {data_client.esquema_rede && data_client.esquema_rede != '' &&
                                <>
                                    <Btn onClick={() => {
                                        // alert(`https://sgi.intecsolutions.com.br:7000/svg_files/${glpi_user.toLowerCase()}.svg`)
                                        if (glpi_level.match(/Super|Técnico/i)) {
                                            localStorage.setItem('IDMR', `${backend_svg_files}/${glpi_user.toLowerCase()}.svg`)
                                            window.open('/networkmap', '_blank');
                                        } else {
                                            history.push("/networkmap", { src_image: `${backend_svg_files}/${glpi_user.toLowerCase()}.svg` })
                                        }
                                    }}
                                        style={{ width: '100%', height: 35, marginTop: 5, textAlign: 'center', justifyContent: 'center', fontSize: '0.8em', cursor: 'pointer' }}>
                                        Esquema de Rede
                                    </Btn>

                                </>
                            }

                            <Btn onClick={() => {
                                window.open('/diagram', '_blank');
                            }}
                                style={{ width: '100%', height: 35, marginTop: 5, textAlign: 'center', justifyContent: 'center', fontSize: '0.8em', cursor: 'pointer' }}>
                                Esquema de Rede V2
                            </Btn>


                            <ExportToTermiusBtn data_client={data_client} userLevel={glpi_level} />
                            {/* <Btn onClick={() => {
                                console.log('get', `/exporttermius/${data_client._id}`)
                                api_bd_nr.get(`/exporttermius/${data_client._id}`).then(response => {
                                    if (response?.data.length > 10) {
                                        const blob = new Blob([response.data], { type: 'text/csv' });
                                        if (window.navigator.msSaveOrOpenBlob) {
                                            window.navigator.msSaveBlob(blob, 'export-to-termius.csv');
                                        }
                                        else {
                                            const elem = window.document.createElement('a');
                                            elem.href = window.URL.createObjectURL(blob);
                                            elem.download = 'export-to-termius.csv';
                                            document.body.appendChild(elem);
                                            elem.click();
                                            document.body.removeChild(elem);
                                        }
                                    }
                                    console.log('exporttermius', response.data)
                                })
                            }}
                                style={{ width: '100%', height: 35, marginTop: 5, textAlign: 'center', justifyContent: 'center', fontSize: '0.8em', cursor: 'pointer' }}>
                                Exportar acessos para o termius
                            </Btn> */}



                            {data_client.grafana && data_client.grafana != '' &&
                                <a target="_blank" href={`${data_client.grafana}`}>
                                    <Btn style={{ width: '100%', height: 35, marginTop: 5, justifyContent: 'center', fontSize: '0.8em' }}>
                                        Grafana
                                    </Btn>
                                </a>
                            }

                            {data_client.mapa_zabbix && data_client.mapa_zabbix != '' &&
                                <a target="_blank" href={`${data_client.mapa_zabbix}`}>
                                    <Btn style={{ width: '100%', height: 35, marginTop: 5, textAlign: 'center', justifyContent: 'center', fontSize: '0.8em' }}>
                                        Mapa do Zabbix
                                    </Btn>
                                </a>
                            }

                            {data_client.weathermap && data_client.weathermap != '' &&
                                <a target="_blank" href={`${data_client.weathermap}`}>
                                    <Btn style={{ width: '100%', height: 35, marginTop: 5, justifyContent: 'center', fontSize: '0.8em' }}>
                                        Weathermap
                                    </Btn>
                                </a>
                            }
                        </div>
                        {/* } */}
                    </div>

                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                        {glpi_level.match(/Super|Técnico/i) &&
                            <>
                                <div
                                    // style={{ cursor: "pointer", marginTop: 5, width: "100%", borderBottom: "1px #fff solid" }} 
                                    className="border-b mt-2 border-white flex cursor-pointer"
                                    onClick={() => { set_show_senhas(!show_senhas) }}
                                >
                                    <span style={{ marginTop: -6, marginLeft: -4, marginRight: 5 }} className="material-icons">
                                        {!show_senhas && 'keyboard_arrow_down'}
                                        {show_senhas && 'keyboard_arrow_up'}
                                    </span>
                                    <b style={{ marginLeft: 0, marginTop: -2, paddingBottom: 5, fontSize: "0.9em" }}
                                    >SENHAS{data_client.senhas.length > 0 && ` (${data_client.senhas.length})`}</b>
                                </div>
                                <div
                                    className={`${show_senhas ? "accordion expanded" : "accordion"}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        overflow: "hidden",
                                        paddingTop: 10,
                                        maxWidth: 170
                                    }}>
                                    {glpi_level.match(/Super|Técnico/i) &&
                                        <div
                                            className='flex items-center'
                                            style={{ marginBottom: 10, cursor: "pointer", fontSize: 13 }}
                                            onClick={() => {
                                                set_content_modal('senhas')
                                                set_show_modal(true)
                                            }}>
                                            <IoIcons.IoIosAddCircle
                                                size={15}
                                                style={{ marginRight: 5 }}
                                            /> NOVA SENHA
                                        </div>
                                    }
                                    {data_client.senhas.map((senha) => (
                                        <div key={senha._id} className="flex flexRow justify-content-sb borda_acima_2_filho" style={{ marginBottom: 10 }}>
                                            <div style={{ maxWidth: 130 }}>
                                                <div style={{ maxWidth: 140, wordWrap: 'break-word', fontSize: 12, marginBottom: 5 }}><b>{senha.descricao}</b></div>
                                                <div style={{ maxWidth: 140, wordWrap: 'break-word', fontSize: 12, marginBottom: 5 }}><b>Usuário:</b> {senha.usuario}</div>
                                                {/* <div style={{ maxWidth: 140, wordWrap: 'break-word', fontSize: 12, marginBottom: 5 }}><b>Senha:</b> {senha.senha}</div> */}

                                                <HidePass
                                                    _style={{ maxWidth: 140, wordWrap: 'break-word', fontSize: 12, marginBottom: 5, cursor: 'pointer' }}
                                                    _txt="Senha: "
                                                    _pass={senha.senha} />
                                            </div>
                                            {glpi_level.match(/Super|Técnico/i) &&
                                                <div className="flex flexRow center-v center-h">
                                                    <IoIcons.IoIosTrash
                                                        size={24}
                                                        style={{ marginRight: 10, cursor: "pointer" }}
                                                        onClick={async () => {
                                                            if (window.confirm(`Tem certeza que deseja remover a senha de: ${senha.descricao}`)) {
                                                                try {
                                                                    const resp = await api_bd.delete(`/customers/deletepassfromlist/${data_client._id}/${senha._id}`)
                                                                    set_data_client(resp.data)
                                                                } catch (error) {
                                                                    if (error.message.indexOf('code 400') > -1) {
                                                                        alert("Verifique os dados e tente novamente.")
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </>
                        }

                        <div
                            className="border-b mt-4 border-white flex cursor-pointer"
                            // style={{ cursor: "pointer", marginTop: 15, width: "100%", borderBottom: "1px #fff solid" }} className="flex"
                            onClick={() => { set_show_defaults(!show_defaults) }}
                        >
                            <span style={{ marginTop: -6, marginLeft: -4, marginRight: 5 }} className="material-icons">
                                {!show_defaults && 'keyboard_arrow_down'}
                                {show_defaults && 'keyboard_arrow_up'}
                            </span>
                            <b style={{ marginLeft: 0, marginTop: -2, paddingBottom: 5, fontSize: "0.9em" }}
                            >PORTAS DEFAULT</b>
                        </div>
                        <div
                            className={`${show_defaults ? "accordion expanded" : "accordion"}`}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                                paddingTop: 10,
                                maxWidth: 170
                            }}>
                            {glpi_level.match(/Super|Técnico/i) &&
                                <div
                                    className='flex items-center'
                                    style={{ marginBottom: 10, cursor: "pointer", fontSize: 13 }}
                                    onClick={() => {
                                        set_content_modal('defaults')
                                        set_show_modal(true)
                                    }}>
                                    <IoIcons.IoIosAddCircle
                                        size={15}
                                        style={{ marginRight: 5 }}
                                    /> Editar Defaults
                                </div>
                            }
                            <div className="flex flexRow justify-content-sb borda_acima_2_filho" style={{ marginBottom: 10 }}>
                                {data_client.defaults &&
                                    <div style={{ maxWidth: 130 }}>
                                        {data_client.defaults.winbox > 0 && <div style={{ maxWidth: 140, wordWrap: 'break-word', fontSize: 12, marginBottom: 5 }}><b>WINBOX: </b>{data_client.defaults.winbox}</div>}
                                        {data_client.defaults.ssh > 0 && <div style={{ maxWidth: 140, wordWrap: 'break-word', fontSize: 12, marginBottom: 5 }}><b>SSH: </b> {data_client.defaults.ssh}</div>}
                                        {data_client.defaults.telnet > 0 && <div style={{ maxWidth: 140, wordWrap: 'break-word', fontSize: 12, marginBottom: 5 }}><b>TELNET: </b> {data_client.defaults.telnet}</div>}
                                    </div>
                                }
                            </div>
                        </div>

                        <div
                            className="border-b mt-4 border-white flex cursor-pointer"
                            // style={{ cursor: "pointer", marginTop: 15, width: "100%", borderBottom: "1px #fff solid" }} className="flex"
                            onClick={() => { set_show_transitos(!show_transitos) }}
                        >
                            <span style={{ marginTop: -6, marginLeft: -4, marginRight: 5 }} className="material-icons">
                                {!show_transitos && 'keyboard_arrow_down'}
                                {show_transitos && 'keyboard_arrow_up'}
                            </span>
                            <b style={{ marginLeft: 0, marginTop: -2, paddingBottom: 5, fontSize: "0.9em" }}
                            >TRÂNSITOS{data_client.transitos.length > 0 && ` (${data_client.transitos.length})`}</b>
                        </div>
                        <div
                            className={`${show_transitos ? "accordion expanded" : "accordion"}`}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                                paddingTop: 10,
                                maxWidth: 170
                            }}>
                            {glpi_level.match(/Super|Técnico/i) &&
                                <div
                                    className='flex items-center'
                                    style={{ marginBottom: 10, cursor: "pointer", fontSize: 13 }}
                                    onClick={() => {
                                        set_content_modal('transit')
                                        set_show_modal(true)
                                    }}>
                                    <IoIcons.IoIosAddCircle
                                        size={15}
                                        style={{ marginRight: 5 }}
                                    /> NOVO TRÂNSITO
                                </div>
                            }
                            {data_client.transitos.map((tra) => (
                                <div key={tra._id} className="flex flexRow justify-content-sb borda_acima_2_filho" style={{ marginBottom: 10 }}>
                                    <div>
                                        <div style={{ fontSize: 12, marginBottom: 5 }}><b>{tra.descricao}</b></div>
                                        <div style={{ fontSize: 12, marginBottom: 5 }}><b>Serviço:</b> {tra.servico}</div>
                                        <div style={{ fontSize: 12, marginBottom: 2 }}><b>IP:</b> {tra.ip}</div>
                                        <div style={{ fontSize: 12, marginBottom: 2 }}><b>Banda:</b> {tra.banda}</div>
                                    </div>
                                    {glpi_level.match(/Super|Técnico/i) &&
                                        <div className="flex flexRow center-v center-h">
                                            <IoIcons.IoIosTrash
                                                size={24}
                                                style={{ marginRight: 10, cursor: "pointer" }}
                                                onClick={async () => {
                                                    if (window.confirm(`Tem certeza que deseja remover o Trânsito: ${tra.descricao}`)) {
                                                        try {
                                                            const resp = await api_bd.delete(`/customers/deletetransit/${data_client._id}/${tra._id}`)
                                                            set_data_client(resp.data)
                                                        } catch (error) {
                                                            if (error.message.indexOf('code 400') > -1) {
                                                                alert("Verifique os dados e tente novamente.")
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>

                        <div
                            className="border-b mt-4 border-white flex cursor-pointer"
                            // style={{ cursor: "pointer", marginTop: 15, width: "100%", borderBottom: "1px #fff solid" }} className="flex"
                            onClick={() => { set_show_ipspublicos(!show_ipspublicos) }}
                        >
                            <span style={{ marginTop: -6, marginLeft: -4, marginRight: 5 }} className="material-icons">
                                {!show_ipspublicos && 'keyboard_arrow_down'}
                                {show_ipspublicos && 'keyboard_arrow_up'}
                            </span>
                            <b style={{ marginLeft: 0, marginTop: -2, paddingBottom: 5, fontSize: "0.9em" }}
                            >IPs PÚBLICOS{data_client.ips_publicos.length > 0 && ` (${data_client.ips_publicos.length})`}</b>
                        </div>
                        <div
                            className={`${show_ipspublicos ? "accordion expanded" : "accordion"}`}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                                paddingTop: 10,
                                maxWidth: 170
                            }}>
                            {glpi_level.match(/Super|Técnico/i) &&
                                <div
                                    className='flex items-center'
                                    style={{ marginBottom: 10, cursor: "pointer", fontSize: 13 }}
                                    onClick={() => {
                                        set_content_modal('pip')
                                        set_show_modal(true)
                                    }}>
                                    <IoIcons.IoIosAddCircle
                                        size={15}
                                        style={{ marginRight: 5 }}
                                    /> NOVO IP PÚBLICO
                                </div>
                            }
                            {data_client.ips_publicos.map((ipp) => (
                                <div key={ipp._id} className="flex flexRow justify-content-sb borda_acima_2_filho" style={{ marginBottom: 10 }}>
                                    <div>
                                        <div style={{ fontSize: 12, marginBottom: 5 }}><b>{ipp.descricao}</b></div>
                                        <div style={{ fontSize: 12, marginBottom: 5 }}><b>IP:</b> {ipp.ip}</div>
                                        <div style={{ fontSize: 12, marginBottom: 2 }}><b>Bloco:</b> {ipp.bloco}</div>
                                    </div>
                                    {glpi_level.match(/Super|Técnico/i) &&
                                        <div className="flex flexRow center-v center-h">
                                            <IoIcons.IoIosTrash
                                                size={24}
                                                style={{ marginRight: 10, cursor: "pointer" }}
                                                onClick={async () => {
                                                    if (window.confirm(`Tem certeza que deseja remover o IP: ${ipp.ip}`)) {
                                                        try {
                                                            const resp = await api_bd.delete(`/customers/deletepublicip/${data_client._id}/${ipp._id}`)
                                                            set_data_client(resp.data)
                                                        } catch (error) {
                                                            if (error.message.indexOf('code 400') > -1) {
                                                                alert("Verifique os dados e tente novamente.")
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>

                        {glpi_level.match(/Super|Técnico/i) &&
                            <>
                                <div
                                    style={{ cursor: "pointer", marginTop: 15, width: "100%", borderBottom: "1px #fff solid" }} className="flex"
                                    onClick={() => {
                                        set_content_modal('cad_station')
                                        set_show_modal(true)
                                    }}
                                >
                                    <IoIcons.IoIosAddCircle
                                        size={15}
                                        style={{ marginRight: 5, marginTop: -2 }}
                                    />
                                    <b style={{ marginLeft: 5, marginTop: -2, paddingBottom: 5, fontSize: "0.9em" }}
                                    >ESTAÇÕES</b>
                                </div>

                                {/* <div
                                    style={{ cursor: "pointer", marginTop: 20, width: "100%", borderBottom: "1px #fff solid" }} className="flex"
                                    onClick={() => {
                                        set_content_modal('obs')
                                        set_show_modal(true)
                                    }}
                                >
                                    <IoIcons.IoIosAddCircle
                                        size={15}
                                        style={{ marginRight: 5, marginTop: -2 }}
                                    />
                                    <b style={{ marginLeft: 5, marginTop: -2, paddingBottom: 5, fontSize: "0.9em" }}
                                    >OBSERVAÇÕES</b>
                                </div> */}
                            </>
                        }


                    </div>
                    {/* FIM da barra lateral */}
                </div>
            )}

            <div className="height_less_header flex flexCol"
                style={{
                    padding: 30,
                    width: "100%",
                    overflowY: "auto"
                }}>
                <div>
                    <Card className={styles.card}>
                        <CardContent>
                            {show_modal === false &&
                                <div style={{ width: "100%", display: "flex", flexDirection: 'row', flexWrap: "wrap", fontSize: "0.8em", justifyContent: 'space-between' }}>
                                    <div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: colors_access_types.VPN }}
                                            onClick={() => {
                                                set_type_filter_access("VPN")
                                            }}
                                            className={type_filter_access == 'VPN' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">shield</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>VPN</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{qtd_type_vpn} ite{qtd_type_vpn > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: colors_access_types.Conta }}
                                            onClick={() => {
                                                set_type_filter_access("Conta")
                                            }}
                                            className={type_filter_access == 'Conta' ? 'animation_filter_active' : ''}
                                        >

                                            <span className="material-icons">supervisor_account</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>Conta</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{qtd_type_conta} ite{qtd_type_conta > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: colors_access_types.Servidor }}
                                            onClick={() => {
                                                set_type_filter_access("Servidor")
                                            }}
                                            className={type_filter_access == 'Servidor' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">storage</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>Servidor</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{qtd_type_servidor} ite{qtd_type_servidor > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: colors_access_types.Ferramenta }}
                                            onClick={() => {
                                                set_type_filter_access("Ferramenta")
                                            }}
                                            className={type_filter_access == 'Ferramenta' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">build</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>Ferramenta</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{qtd_type_ferramenta} ite{qtd_type_ferramenta > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: colors_access_types['Ativo de Rede'] }}
                                            onClick={() => {
                                                set_type_filter_access("Ativo de Rede")
                                            }}
                                            className={type_filter_access == 'Ativo de Rede' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">router</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>Ativo de Rede</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{qtd_type_ativo} ite{qtd_type_ativo > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: "#999999" }}
                                            onClick={() => {
                                                set_type_filter_access("Todos")
                                            }}
                                        >
                                            <span style={{ marginRight: 2 }} className="material-icons">remove_circle</span> Limpar
                                        </div>
                                    </div>

                                    <div style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: "#999999", alignSelf: 'flex-end', marginRight: 15 }}>
                                        <div style={{ marginBottom: -7, marginRight: 5 }}>
                                            <span className="material-icons">search</span>
                                        </div>
                                        <div style={{ width: 'auto' }}>
                                            <Autocomplete
                                                style={{ width: 270, marginBottom: 17, fontSize: "0.8em" }}
                                                options={data_stations}
                                                getOptionLabel={(option) => `${option.codigo_estacao} - ${option.nome}`}
                                                id="localidade"
                                                autoComplete
                                                includeInputInList
                                                renderInput={(params) => <TextField {...params} label=" Filtrar por Estações" margin="normal" />}
                                                onChange={async (e) => {
                                                    const filter = e.target.innerText ? e.target.innerText.split(' - ')[0] : ''
                                                    set_filter_access(filter)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 30,
                                            width: "100%",
                                            color: "#999999",
                                            fontWeight: "bolder",
                                            fontSize: "0.8em"
                                        }}
                                    >
                                        FILTROS VISUAIS
                                    </div>
                                </div>
                            }
                        </CardContent>
                    </Card>

                </div>


                {Object.keys(data_client).length > 0 && data_access && data_access.length > 0 && (
                    <div style={{
                        // overflowY: "auto" 
                    }}>
                        <Card className={styles.card}>
                            <CardContent>
                                <div className="flex flexCol">
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={['VPN', 'Conta']} _situacao="Ativo" />
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["Servidor"]} _situacao="Ativo" />
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["Ferramenta"]} _situacao="Ativo" />
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["Ativo de Rede"]} _situacao="Ativo" />

                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["VPN"]} _situacao="Inativo" />
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["Conta"]} _situacao="Inativo" />
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["Servidor"]} _situacao="Inativo" />
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["Ferramenta"]} _situacao="Inativo" />
                                    <ListAccessByType dados={data_access} set_content_modal={set_content_modal} set_access_to_edit={set_access_to_edit} set_show_modal={set_show_modal} _filter={["Ativo de Rede"]} _situacao="Inativo" />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}

                {Object.keys(data_client).length > 0 && data_access && data_access.length == 0 &&
                    <Card
                        className={`${styles.card} flex center-h center-v`}
                    >
                        <CardContent style={{ padding: 0 }}>
                            <h3>Nenhum Acesso Cadastrado</h3>
                        </CardContent>
                    </Card>
                }
            </div>
            {glpi_level.match(/Super|Técnico/i) && (show_modal === false) &&
                <Fab
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        set_content_modal('access')
                        set_show_modal(true)
                    }}
                >
                    <span className="material-icons" style={{ fontSize: 33, color: "white", marginLeft: 0 }}>note_add</span>
                </Fab>
            }
        </div>
    );
}

export default ClientDetails;


