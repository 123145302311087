import React, {
    memo,
    useEffect,
    useState
} from 'react';

import './Outages.css'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { profileData } from '../../atoms/user.atom'
import outagesComponentToShowState from '../../atoms/outages_state.atom'

import { msToTime, msToElapsedTime } from '../../util/fns';



const CounterTime = (props) => {
    const [time, setTime] = useState({
        horas: '00',
        minutos: '00',
        segundos: '00',
    })
    const created = new Date(props.created).getTime()

    useEffect(() => {
        let interval = setInterval(() => {
            const actualTime = new Date().getTime()
            setTime(msToTime(actualTime - created))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    // return <span style={{ fontSize: '1.8em' }}>{`${time?.horas}:${time?.minutos}`}</span>
    return <span style={{ fontSize: '1.5em' }}>{`${time?.horas}:${time?.minutos}`}</span>
}


function OutageRow(props) {
    const ev = props.ev

    const setComponentToShow = useSetRecoilState(outagesComponentToShowState)

    const profile_user = useRecoilValue(profileData)

    const verifyIsRunning = (ev) => {
        return ev.tratativas.find(tr => (
            (tr.id_usuario === profile_user.id) && (tr.inicio !== null && tr.fim === null)
        )) ? true : false
    }

    const isRunning = verifyIsRunning(ev)

    const et = ev.tratativas.filter(tr => tr.fim !== null).reduce(function (accumulator, curValue) {
        return accumulator + curValue.elapsedTime
    }, 0)

    let elapsedTime = 0
    if (isRunning === false) {
        elapsedTime = msToTime(et)
    } else {
        let ini = ev.tratativas.filter(tr => tr.fim === null)[0].inicio
        let timeIni = new Date(ini).getTime()
        let timeEnd = new Date().getTime()
        elapsedTime = msToTime((et + (timeEnd - timeIni)))
    }

    const minutesToAlert = 60
    let blinkClass = ''

    if (ev.interacoes.length > 0) {
        blinkClass = (ev.status === 'aberto' && (((new Date().getTime() - new Date(ev.interacoes[ev.interacoes.length - 1].data).getTime() > (minutesToAlert * 60 * 1000)))) ? ' blink' : '')
    } else {
        blinkClass = (ev.status === 'aberto' && (((new Date().getTime() - new Date(ev.createdAt).getTime() > (minutesToAlert * 60 * 1000)))) ? ' blink' : '')
    }


    const colorStatus = () => {
        switch (ev.tipo_evento) {
            case 'Estação Indisponível':
                return 'critico'
                break;

            case 'Falha Elétrica':
                return 'alerta'
                break;

            case 'Link Com Problema':
                switch (ev.qual_o_problema) {
                    case 'ATENUAÇÃO':
                        return 'alerta'
                        break
                    case 'BAIXA PERFORMANCE':
                        return 'alerta'
                        break
                    case 'INDISPONIBILIDADE':
                        return 'critico'
                        break
                    case 'ROMPIMENTO':
                        return 'critico'
                        break
                    case 'TAXAÇÃO DE ERROS':
                        return 'alerta'
                        break

                    default:
                        return ''
                        break
                }
                return 'critico'
                break;

            default:
                return ''
                break;
        }
    }

    const styleDestopView = { width: '99%', marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 15, marginRight: 3, marginLeft: 3, minHeight: 'auto', position: 'relative' }
    const styleTvView = { width: '24%', minWidth: 500, marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 15, marginRight: 3, marginLeft: 3, minHeight: 'auto', position: 'relative' }

    return (
        <div
            className={`card ${blinkClass} ${props?.tvView === 'tv' ? 'bg-[#3c5ca6] text-white' : colorStatus()}`}
            style={props?.tvView === 'tv' ? styleTvView : styleDestopView}
        >
            {/* LINE BUTTONS */}
            {/* teste */}
            {props?.tvView === 'tv' &&
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: '100%', fontSize: '3.3em', borderBottom: '1px solid  #fff' }} >
                        <span style={{ fontSize: '0.8em' }}>#</span>{String(ev.cod_id).padStart(3, '0')}

                        <div className={`ml-3 mr-[-30px] ${blinkClass ? 'blinkopacity' : 'opacity-0'}`}>
                            <img
                                src='alert.png'
                                style={{
                                    width: '30px',
                                    filter: `drop-shadow(2px 2px 2px #ffffff)`
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', width: '100%', fontSize: '1.8em', borderBottom: '1px solid  #fff' }} >
                        {ev.tipo_evento} {ev.tipo_evento === 'Link Com Problema' ? '(' + ev.qual_o_problema + ')' : ''}
                    </div>
                    <div
                        // className={`${blinkClass ? 'blinkbg' : ''}`}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            width: '100%',
                            fontSize: '1.8em',
                            borderBottom: '1px solid  #fff'
                        }}
                    >
                        <CounterTime created={ev.createdAt} alert={blinkClass} />
                        <span style={{ fontSize: '0.6em', marginTop: -10 }}>DURAÇÃO DO EVENTO</span>
                    </div>
                </div>
            }
            {/* fianl teste */}

            {props?.tvView === 'desktop' &&
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid  #3c5ca6', marginBottom: 10 }}>
                    <div className='row-outage-title' >
                        Nº: {(ev.cod_id >= 0 && ev.cod_id < 10) ? '000' : (ev.cod_id >= 10 && ev.cod_id < 100) ? '00' : (ev.cod_id >= 100 && ev.cod_id < 1000) ? '0' : ''}{ev.cod_id} - {ev.tipo_evento} {ev.tipo_evento === 'Link Com Problema' ? '(' + ev.qual_o_problema + ')' : ''} {blinkClass !== '' ? ' - Mais de 1 hora sem atualização do ticket' : ''}
                    </div>

                    {/* Action Buttons */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', gap: 12, justifyContent: 'flex-end' }}>
                        <div style={{ flexDirection: 'row', cursor: 'pointer', marginBottom: ev.interacoes.length > 0 ? -8 : -2 }}
                            onClick={() => {
                                setComponentToShow({
                                    component: 'outageDetail',
                                    data: ev
                                })
                            }}
                        >
                            {ev.interacoes.length > 0 &&
                                <div className='default_color' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderRadius: 12, position: 'absolute', marginLeft: 12, marginTop: -5, color: '#fff', fontSize: 16 }}>{ev.interacoes.length}</div>
                            }
                            <span className="material-icons left_icon" style={{ marginLeft: 0, marginRight: 0, fontSize: '2.8em' }}>menu</span>
                        </div>
                    </div>

                </div>
            }
            {/* END LINE BUTTONS */}

            {/* CONTENT ITEM */}
            <div className='row-outage-data'>
                {/* <span style={{ margin: 0, }}>Cliente: {ev.customer_name}</span> */}
                {ev.tipo_evento === 'Estação Indisponível' &&
                    <>
                        <p style={{ margin: 0, }}>Estação: {ev.estacao}</p>
                        <p style={{ margin: 0, }}>Protocolo: {ev.numero_protocolo}</p>
                    </>
                }
                {ev.tipo_evento === 'Falha Elétrica' &&
                    <>
                        <p style={{ margin: 0, }}><b>Estação:</b> {ev.estacao}</p>
                        <p style={{ margin: 0, }}><b>Responsável:</b> {ev.responsavel_estacao}</p>
                        {ev.autonomia_eletrica && <p style={{ margin: 0, marginBottom: 3, whiteSpace: 'pre-line' }}><b>Autonomia da estação:</b> Aprox. {ev.autonomia_eletrica}hs</p>}
                    </>
                }
                {ev.tipo_evento === 'Link Com Problema' &&
                    <>
                        <span style={{ margin: 0, }}>Fornecedor: {ev.fornecedor}</span>
                        <span style={{ margin: 0, }}>Protocolo: {ev.numero_protocolo}</span>
                    </>
                }

                {ev.tipo_evento === 'Falha de OLT' &&
                    <>
                        <span style={{ margin: 0, whiteSpace: 'pre-line' }}><b>Estação:</b> {ev.estacao}</span>
                        <span style={{ margin: 0, whiteSpace: 'pre-line' }}><b>SLOT/PON:</b> {ev.slot_pon}</span>
                        <span style={{ margin: 0, whiteSpace: 'pre-line' }}><b>Problema:</b> {ev.qual_o_problema}</span>
                        <span style={{ margin: 0, whiteSpace: 'pre-line' }}><b>Responsável:</b> {ev.responsabilidade}</span>
                    </>
                }

                {ev.trecho_ponto_a ? <span style={{ margin: 0, }}>Trecho Ponto A: {ev.trecho_ponto_a}</span> : null}
                {ev.trecho_ponto_b ? <span style={{ margin: 0, }}>Trecho Ponto B: {ev.trecho_ponto_b}</span> : null}
                {ev.sub_trecho_ponto_a ? <span style={{ margin: 0, }}>Sub Trecho Ponto A: {ev.sub_trecho_ponto_a}</span> : null}
                {ev.sub_trecho_ponto_a ? <span style={{ margin: 0, }}>Sub Trecho Ponto B: {ev.sub_trecho_ponto_b}</span> : null}

                <span style={{ margin: 0, }}>Horário do incidente: {ev.horario_inicio}</span>

                {props?.tvView === 'desktop' &&
                    <>
                        <span style={{ margin: 0, }}>Última interação: {ev.interacoes.length > 0 ? new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleDateString() + ' às ' + new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleTimeString() : 'Nenhuma interação realizada neste ticket'}</span>
                        {/* {ev.evento_solucionado?.status === true && <p style={{ margin: 0, }}>Solução do evento: {new Date(ev.evento_solucionado.horario).toLocaleDateString()} às {new Date(ev.evento_solucionado.horario).toLocaleTimeString()}</p> } */}
                        {ev.status === 'fechado' && <span style={{ margin: 0, }}>Ticket Finalizado em: {new Date(ev.horario_fim).toLocaleDateString()} às {new Date(ev.horario_fim).toLocaleTimeString()}</span>}
                        {/* <span style={{ margin: 0, }}>Tempo de tratativa: {elapsedTime.horas}:{elapsedTime.minutos}:{elapsedTime.segundos}</span> */}
                        <span style={{ margin: 0, }}>Ticket aberto em: {new Date(ev.createdAt).toLocaleDateString()} às {new Date(ev.createdAt).toLocaleTimeString()}</span>
                    </>
                }
            </div>
            {/* END CONTENT ITEM */}

        </div>
    )
}

export default memo(OutageRow);