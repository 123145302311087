import React, {
    useState,
    useEffect
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Checkbox from '@material-ui/core/Checkbox';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles, { primary_color } from '../../components/Styles'

import FileUploader from '../FileUploader'
import FileDropzone from '../DropZone';

import Toast from '../Toast'

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { profileData } from '../../atoms/user.atom'
import navigationState from '../../atoms/navigate_state.atom'


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

import Autocomplete from '@material-ui/lab/Autocomplete';


function FormDataTickets(props) {
    const { data_client, width, data_client_update } = props
    const styles = Styles();
    const loggedUser = useRecoilValue(profileData)

    const [show_toast, set_show_toast] = useState(false)
    const [content_toast, set_content_toast] = useState(null)

    const [form_data, set_form_data] = useState({
        tipo_solicitacao: 'selecione',
        categoria: 'selecione',
        prioridade: 'padrão',
        situacao: '',
        subcategoria: 'selecione',
        reincidente: '',
        nome: '',
        cliente: '',
        fornecedor: '',
        tipo: 'selecione',
        tipo_ativo: 'selecione',
        tipo_alteracao: 'selecione',
        servico: '',
        situacao_do_servico: 'selecione',
        ponto_a: '',
        ponto_b: '',
        as_remoto: '',
        asn_ou_prefixo: '',
        cliente_as_ou_prefixo: '',
        usuario: '',
        senha: '',
        localidade: '',
        descricao_local: '',
        ip: '',
        ip_origem: '',
        ip_destino: '',
        ip_cdn: '',
        vlan_e_ip: '',
        quantidade_ips: '',
        banda_contratada: '',
        nova_banda: '',
        designador: '',
        fabricante_e_modelo: '',
        tracerouter: '',
        url: '',
        info_cliente_1: '',
        info_cliente_2: '',
        titulo: '',
        detalhes_solicitacao: '',
        anexo: false,
        indisponibilidade: 'Não'
    })
    const [anexo, set_anexo] = useState(false);

    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const [condicoes, set_condicoes] = useState({})

    const set_navigation_state = useSetRecoilState(navigationState)

    const [list_customers, set_list_customers] = useState([])

    const [requerente, set_requerente] = useState({ nome: '', id: '' })

    const load_users = async () => {
        const lista = await api_bd.get('/users')
        const alphabetically = lista.data.filter(user => user.ativo === true).sort((a, b) => a.nome.localeCompare(b.nome))
        set_list_customers([{ _id: loggedUser.id, nome: loggedUser.nome }, ...alphabetically.filter(user => (user.nivel.match(/Cliente/)))])
    }

    const form_labels = {
        tipo_solicitacao: 'tipo de solicitação',
        categoria: 'categoria',
        prioridade: 'prioridade',
        subcategoria: 'subcategoria',
        reincidente: 'reincidente',
        nome: 'nome',
        cliente: 'cliente',
        fornecedor: 'fornecedor',
        tipo: 'tipo',
        tipo_ativo: 'tipo de ativo',
        tipo_alteracao: 'tipo de alteração',
        servico: 'serviço',
        situacao_do_servico: 'situação do serviço',
        ponto_a: 'ponto a',
        ponto_b: 'ponto b',
        as_remoto: 'as remoto',
        asn_ou_prefixo: 'ASN/Prefixo',
        cliente_as_ou_prefixo: 'Cliente AS/Prefixo',
        usuario: 'usuário',
        senha: 'senha',
        localidade: 'localidade',
        descricao_local: 'descrição da localidade',
        ip: 'ip',
        ip_origem: 'ip de origem',
        ip_destino: 'ip de destino',
        ip_cdn: 'ip do cdn',
        vlan_e_ip: 'vlan e ip',
        quantidade_ips: 'quantidade de ips',
        banda_contratada: 'banda contratada',
        nova_banda: 'nova banda',
        designador: 'designador',
        fabricante_e_modelo: 'fabricante e modelo',
        tracerouter: 'tracerouter',
        url: 'url',
        info_cliente_1: 'informação do cliente 1',
        info_cliente_2: 'informação do cliente 2',
        titulo: 'título',
        detalhes_solicitacao: 'detalhes da solicitação',
        anexo: 'anexo',
        indisponibilidade: 'indisponibilidade'
    }

    const handle_condition = () => {
        return new Promise((resolve, reject) => {
            const data = {
                tipo_solicitacao: false,//true,

                categoria: false,//true,

                prioridade: true,

                subcategoria: false,//(form_data.categoria === 'ativo_de_rede') ? true : false,

                reincidente: false,//(form_data.tipo_solicitacao === 'suporte_provedor' || form_data.tipo_solicitacao === 'servico_cliente_provedor') ? true : false,

                nome: true,

                cliente: false,//(form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'suporte' || form_data.categoria === 'upgrade_downgrade') ? true : false,

                fornecedor: false,//(form_data.categoria === 'link') ? true : false,

                tipo: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'suporte' || form_data.categoria === 'upgrade_downgrade') ? true : false,

                tipo_ativo: false,//(form_data.categoria === 'ativo_de_rede') ? true : false,

                tipo_alteracao: false,//(form_data.categoria === 'ativo_de_rede' && form_data.subcategoria === 'info_alteracao') ? true : false,

                servico: false,//(form_data.categoria === 'falha_acesso_servico') ? true : false,

                situacao_do_servico: false,//(form_data.categoria === 'falha_acesso_servico') ? true : false,

                ponto_a: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'suporte' || form_data.categoria === 'transporte' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo === 'transporte') ? true : false,
                ponto_b: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'suporte' || form_data.categoria === 'transporte' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo === 'transporte') ? true : false,

                as_remoto: false,//(form_data.tipo === 'link') ? true : false,

                asn_ou_prefixo: false,//(form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'suporte' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn') ? true : false,

                cliente_as_ou_prefixo: false,//(form_data.categoria === 'ativacao' || form_data.categoria === 'liberacao_prefixo') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn') ? true : false,

                usuario: false,//(form_data.categoria === 'vpn') ? true : false,
                senha: false,//(form_data.categoria === 'vpn') ? true : false,

                localidade: false,//(form_data.categoria === 'olt' || form_data.categoria === 'roteador' || form_data.categoria === 'servidor' || form_data.categoria === 'switch' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'ativo_de_rede' || form_data.categoria === 'falha_massiva' || form_data.categoria === 'vpn' || form_data.categoria === 'ativacao_transporte_transito') && (form_data.tipo !== 'transporte') ? true : false,

                descricao_local: false,//(form_data.categoria === 'olt' || form_data.categoria === 'roteador' || form_data.categoria === 'servidor' || form_data.categoria === 'switch' || form_data.categoria === 'ativo_de_rede') ? true : false,

                ip: false,//(form_data.categoria === 'suporte' || form_data.categoria === 'servidor' || form_data.categoria === 'ativo_de_rede' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo_solicitacao === 'suporte_provedor' || form_data.tipo === 'dedicado') && (form_data.tipo_solicitacao === 'suporte_provedor' || form_data.subcategoria === 'info_alteracao') ? true : false,

                ip_origem: false,//(form_data.categoria === 'falha_acesso_servico' || form_data.categoria === 'suporte') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'dedicado') ? true : false,
                ip_destino: false,//(form_data.categoria === 'falha_acesso_servico' || form_data.categoria === 'suporte') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'dedicado') ? true : false,

                ip_cdn: false,//(form_data.categoria === 'suporte' && form_data.tipo === 'cdn') ? true : false,

                vlan_e_ip: false,//(form_data.tipo === 'link') ? true : false,

                quantidade_ips: false,//(form_data.categoria === 'pool_ip_clientes') ? true : false,

                banda_contratada: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'transporte' || form_data.tipo === 'link') ? true : false,

                nova_banda: false,//(form_data.categoria === 'upgrade_downgrade') ? true : false,

                designador: false,//(form_data.categoria === 'ativacao_transporte_transito') ? true : false,

                fabricante_e_modelo: false,//(form_data.categoria === 'olt' || form_data.categoria === 'roteador' || form_data.categoria === 'servidor' || form_data.categoria === 'switch' || form_data.categoria === 'ativo_de_rede') ? true : false,

                tracerouter: false,// (form_data.categoria === 'suporte') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'dedicado') ? true : false,

                url: false,//(form_data.categoria === 'falha_acesso_servico' || form_data.categoria === 'servidor') ? true : false,

                info_cliente_1: false,//(form_data.categoria === 'falha_massiva') ? true : false,
                info_cliente_2: false,//(form_data.categoria === 'falha_massiva') ? true : false,

                titulo: true,

                detalhes_solicitacao: true,
                anexo: (form_data.nome !== '') ? true : false,
                indisponibilidade: true
            }
            resolve(data)
        })
    }



    const handle_form_data = (key, value) => {
        let data = { ...form_data }
        data[key] = value
        if (key === 'tipo_solicitacao') {
            data["categoria"] = 'selecione'
            data["subcategoria"] = 'selecione'
            data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'categoria') {
            // data["categoria"] = 'selecione'
            data["subcategoria"] = 'selecione'
            data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'subcategoria') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'tipo') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            // data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'tipo_ativo') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            // data["tipo"] = 'selecione'
            // data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'tipo_alteracao') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            // data["tipo"] = 'selecione'
            // data["tipo_ativo"] = 'selecione'
            // data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        }
        set_form_data(data)
    }

    const zero_form = () => {
        set_anexo(false)
        const zerodata = {
            tipo_solicitacao: 'selecione',
            categoria: 'selecione',
            prioridade: 'padrão',
            situacao: '',
            subcategoria: 'selecione',
            reincidente: '',
            nome: '',
            cliente: '',
            fornecedor: '',
            tipo: 'selecione',
            tipo_ativo: 'selecione',
            tipo_alteracao: 'selecione',
            servico: '',
            situacao_do_servico: 'selecione',
            ponto_a: '',
            ponto_b: '',
            as_remoto: '',
            asn_ou_prefixo: '',
            cliente_as_ou_prefixo: '',
            usuario: '',
            senha: '',
            localidade: '',
            descricao_local: '',
            ip: '',
            ip_origem: '',
            ip_destino: '',
            ip_cdn: '',
            vlan_e_ip: '',
            quantidade_ips: '',
            banda_contratada: '',
            nova_banda: '',
            designador: '',
            fabricante_e_modelo: '',
            tracerouter: '',
            url: '',
            info_cliente_1: '',
            info_cliente_2: '',
            titulo: '',
            detalhes_solicitacao: '',
            anexo: false,
            indisponibilidade: 'Não'
        }
        set_form_data({ ...zerodata })
    }

    useEffect(() => {
        (async () => {
            await load_users()
        })()


        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)

        if (loggedUser.nivel.match(/Super|Técnico/i)) {
            set_requerente({ nome: loggedUser.nome, id: loggedUser.id })
            //console.log('Logged User: ', loggedUser)
        }
    }, [])


    useEffect(() => {
        (async () => {
            const data_cond = await handle_condition()
            // console.log(data_cond)
            set_condicoes(data_cond)
            // console.log('Form Data: ', form_data)
            // console.log('Condições: ', data_cond)
        })()
    }, [form_data])


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: 1,
            left: 0,
            top: 0,
            maxWidth: '90%',
            height: device_height - 50,
            maxHeight: device_height - 50,
            justifyContent: 'center',
            alignItems: 'flex-start',
            overflowY: "auto"
        }}>
            <div className={styles.div_root_component} style={{ width, zIndex: 1 }}>
                {show_toast &&
                    <Toast _style={{ backgroundColor: '#3c5ca6cc', color: 'white' }}>{content_toast}</Toast>
                }
                <Card
                    style={{
                        margin: 10,
                        minWidth: 150,
                        minHeight: 75,
                        color: primary_color
                    }}>
                    <CardContent>
                        <CardHeader
                            title="NOVO CHAMADO"
                            subheader=""
                        />
                        {/* <form className={styles.form_cad_user} noValidate autoComplete="off"> */}
                        <form style={{
                            display: "flex",
                            flexDirecion: "row",
                            flexWrap: "wrap",
                            width: (device_width - 214) * 0.7,
                            minWidth: (device_width - 214) * 0.7,
                            maxWidth: (device_width - 214) * 0.7,
                            zIndex: 99999
                        }} noValidate autoComplete="off">
                            {list_customers && list_customers.length > 0 && loggedUser.nivel.match(/Super|Técnico/i) &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-requerente">Requerente &nbsp; &nbsp;  *</InputLabel>
                                        <Select
                                            value={`${requerente.nome}|:|${requerente.id}`}
                                            defaultValue={`${loggedUser.nome}|:|${loggedUser.id}`}
                                            onChange={e => {
                                                const [nome, id] = e.target.value.split('|:|')
                                                set_requerente({ nome: `${nome}`, id })
                                            }}
                                        >
                                            {list_customers.map(el => (<MenuItem key={el._id} value={`${el.nome}|:|${el._id}`}>{el.nome}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {condicoes.tipo_solicitacao &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-tipo-solicitacao">Tipo de solicitação &nbsp; &nbsp;  *</InputLabel>
                                        <Select
                                            value={form_data?.tipo_solicitacao}
                                            defaultValue={'implantacao_homologacao'}
                                            labelId="select-tipo-solicitacao"
                                            onChange={e => {
                                                handle_form_data("tipo_solicitacao", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="selecione">Selecione</MenuItem>
                                            <MenuItem value="implantacao_homologacao">Implantação/Homologação</MenuItem>
                                            <MenuItem value="servico_cliente_provedor">Serviço ao cliente do provedor</MenuItem>
                                            <MenuItem value="suporte_provedor">Suporte ao provedor</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {condicoes.categoria &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label-categoria">Categoria &nbsp; &nbsp;  *</InputLabel>
                                        {form_data && form_data?.tipo_solicitacao === 'selecione' &&
                                            <Select
                                                value={form_data?.categoria}
                                                defaultValue={'ativacao_transporte_transito'}
                                                labelId="select-label-categoria"
                                                onChange={e => {
                                                    handle_form_data("categoria", e.target.value)
                                                }}
                                            >
                                                <MenuItem value="selecione">Selecione</MenuItem>
                                            </Select>
                                        }
                                        {form_data && form_data?.tipo_solicitacao !== 'selecione' &&
                                            <>
                                                {form_data.tipo_solicitacao === 'implantacao_homologacao' &&
                                                    <>
                                                        <Select
                                                            value={form_data?.categoria}
                                                            defaultValue={'ativacao_transporte_transito'}
                                                            labelId="select-label-categoria"
                                                            onChange={e => {
                                                                handle_form_data("categoria", e.target.value)
                                                            }}
                                                        >
                                                            <MenuItem value="selecione">Selecione</MenuItem>
                                                            <MenuItem value="ativacao_transporte_transito">Ativação transporte/trânsito</MenuItem>
                                                            <MenuItem value="olt">OLT</MenuItem>
                                                            <MenuItem value="pool_ip_clientes">Pool de IP para clientes</MenuItem>
                                                            <MenuItem value="roteador">Roteador</MenuItem>
                                                            <MenuItem value="servidor">Servidor</MenuItem>
                                                            <MenuItem value="switch">Switch</MenuItem>
                                                        </Select>
                                                    </>
                                                }

                                                {form_data.tipo_solicitacao === 'servico_cliente_provedor' &&
                                                    <>
                                                        <Select
                                                            value={form_data?.categoria}
                                                            defaultValue={'ativacao_transporte_transito'}
                                                            labelId="select-label-categoria"
                                                            onChange={e => {
                                                                handle_form_data("categoria", e.target.value)
                                                            }}
                                                        >
                                                            <MenuItem value="selecione">Selecione</MenuItem>
                                                            <MenuItem value="ativacao">Ativação</MenuItem>
                                                            <MenuItem value="desativacao">Desativação</MenuItem>
                                                            <MenuItem value="falha_acesso_servico">Falha acesso/serviço</MenuItem>
                                                            <MenuItem value="liberacao_prefixo">Liberação de prefixo</MenuItem>
                                                            <MenuItem value="suporte">Suporte</MenuItem>
                                                            <MenuItem value="upgrade_downgrade">Upgrade/Downgrade</MenuItem>
                                                        </Select>
                                                    </>
                                                }

                                                {form_data.tipo_solicitacao === 'suporte_provedor' &&
                                                    <>
                                                        <Select
                                                            value={form_data?.categoria}
                                                            defaultValue={'ativacao_transporte_transito'}
                                                            labelId="select-label-categoria"
                                                            onChange={e => {
                                                                handle_form_data("categoria", e.target.value)
                                                            }}
                                                        >
                                                            <MenuItem value="selecione">Selecione</MenuItem>
                                                            <MenuItem value="ativo_de_rede">Ativo de Rede</MenuItem>
                                                            <MenuItem value="falha_massiva">Falha massiva</MenuItem>
                                                            <MenuItem value="link">Link</MenuItem>
                                                            <MenuItem value="monitoramento">Monitoramento</MenuItem>
                                                            <MenuItem value="transporte">Transporte</MenuItem>
                                                            <MenuItem value="vpn">VPN</MenuItem>
                                                            <MenuItem value="servidor">Servidor</MenuItem>
                                                        </Select>
                                                    </>
                                                }
                                            </>
                                        }
                                    </FormControl>
                                </div>
                            }

                            {condicoes.prioridade &&
                                <div style={{ width: "100%", display: 'flex', flexDirection: 'row', borderBottom: '1px #888888 solid' }}>
                                    <div style={{
                                        color: "#888888",
                                        marginLeft: 0,
                                        marginTop: 18,
                                        marginBottom: 5,
                                        top: 10
                                    }}>Prioridade: </div>
                                    <div style={{
                                        paddingLeft: 5,
                                        color: "#888888",
                                        marginLeft: 0,
                                        marginTop: 16,
                                        top: 10
                                    }}>
                                        <span style={{ margin: 2, fontSize: '1.1em', cursor: 'pointer', color: form_data.prioridade === 'padrão' ? "#888888" : form_data.prioridade === 'baixa' ? "#228B22" : form_data.prioridade === 'média' ? "#ff7900" : "#ff0000" }}
                                            class="material-icons"
                                            onClick={() => {
                                                handle_form_data("prioridade", 'baixa')
                                            }}
                                        >warning</span>
                                        <span style={{ margin: 2, fontSize: '1.1em', cursor: 'pointer', color: form_data.prioridade === 'média' ? "#ff7900" : form_data.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                            class="material-icons"
                                            onClick={() => {
                                                handle_form_data("prioridade", 'média')
                                            }}
                                        >warning</span>
                                        <span style={{ margin: 2, fontSize: '1.1em', cursor: 'pointer', color: form_data.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                            class="material-icons"
                                            onClick={() => {
                                                handle_form_data("prioridade", 'alta')
                                            }}
                                        >warning</span>
                                    </div>
                                </div>
                            }

                            {condicoes.subcategoria &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label-subcategoria">Subcategoria &nbsp; &nbsp;  *</InputLabel>
                                        <Select
                                            value={form_data?.subcategoria}
                                            defaultValue={'manutencao'}
                                            labelId="select-label-subcategoria"
                                            onChange={e => {
                                                handle_form_data("subcategoria", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="selecione">Selecione</MenuItem>
                                            <MenuItem value="manutencao">Manutenção</MenuItem>
                                            <MenuItem value="substituicao">Substituição</MenuItem>
                                            <MenuItem value="info_alteracao">Informar alteração</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {condicoes.reincidente &&
                                <div style={{ width: "100%", display: 'flex', flexDirection: 'row', borderBottom: '1px #888888 solid' }}>
                                    <div style={{
                                        color: "#888888",
                                        marginLeft: 0,
                                        marginTop: 15,
                                        marginBottom: 5,
                                        top: 10
                                    }}>Reincidente: </div>
                                    <Checkbox
                                        style={{ marginTop: 2, marginLeft: -10 }}
                                        checked={form_data.reincidente === 'Sim' ? true : false}
                                        onChange={e => {
                                            handle_form_data("reincidente", e.target.checked ? 'Sim' : 'Não')
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                </div>
                            }

                            {condicoes.nome &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("nome", e.target.value)
                                        }}
                                        id="nome"
                                        label="Seu nome"
                                        inputProps={{ placeholder: "Digite o nome do colaborador que está realizando a abertura do chamado" }}
                                        required={true}
                                        value={form_data?.nome}
                                    />
                                </div>
                            }

                            {condicoes.cliente &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("cliente", e.target.value)
                                        }}
                                        id="cliente"
                                        label="Cliente"
                                        inputProps={{ placeholder: "Digite o nome, razão, usuário ou identificador do cliente que será atendido" }}
                                        required={true}
                                        value={form_data?.cliente}
                                    />
                                </div>
                            }

                            {condicoes.fornecedor &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("fornecedor", e.target.value)
                                        }}
                                        id="fornecedor"
                                        label="Fornecedor"
                                        inputProps={{ placeholder: "Digite o nome, razão, usuário ou identificador do fornecedor do link" }}
                                        required={true}
                                        value={form_data?.fornecedor}
                                    />
                                </div>
                            }

                            {condicoes.tipo &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-tipo">Tipo &nbsp; &nbsp;  *</InputLabel>
                                        {form_data.categoria === 'ativacao_transporte_transito' &&
                                            <Select
                                                value={form_data?.tipo}
                                                defaultValue={'transporte'}
                                                labelId="select-tipo"
                                                onChange={e => {
                                                    handle_form_data("tipo", e.target.value)
                                                }}
                                            >
                                                <MenuItem value="selecione">Selecione</MenuItem>
                                                <MenuItem value="link">Link</MenuItem>
                                                <MenuItem value="transporte">Transporte</MenuItem>
                                            </Select>
                                        }
                                        {form_data.categoria === 'liberacao_prefixo' &&
                                            <Select
                                                value={form_data?.tipo}
                                                defaultValue={'transporte'}
                                                labelId="select-tipo"
                                                onChange={e => {
                                                    handle_form_data("tipo", e.target.value)
                                                }}
                                            >
                                                <MenuItem value="selecione">Selecione</MenuItem>
                                                <MenuItem value="bgp">BGP</MenuItem>
                                                <MenuItem value="cdn">CDN</MenuItem>
                                            </Select>
                                        }
                                        {(form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'suporte' || form_data.categoria === 'upgrade_downgrade') &&
                                            <Select
                                                value={form_data?.tipo}
                                                defaultValue={'transporte'}
                                                labelId="select-tipo"
                                                onChange={e => {
                                                    handle_form_data("tipo", e.target.value)
                                                }}
                                            >
                                                <MenuItem value="selecione">Selecione</MenuItem>
                                                <MenuItem value="bgp">BGP</MenuItem>
                                                <MenuItem value="cdn">CDN</MenuItem>
                                                <MenuItem value="dedicado">Dedicado</MenuItem>
                                                <MenuItem value="transporte">Transporte</MenuItem>
                                            </Select>
                                        }
                                    </FormControl>
                                </div>
                            }

                            {condicoes.tipo_ativo &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label-tipo-ativo">Tipo de ativo &nbsp; &nbsp;  *</InputLabel>
                                        <Select
                                            value={form_data?.tipo_ativo}
                                            defaultValue={'roteador'}
                                            labelId="select-label-tipo-ativo"
                                            onChange={e => {
                                                handle_form_data("tipo_ativo", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="selecione">Selecione</MenuItem>
                                            <MenuItem value="radio">Rádio</MenuItem>
                                            <MenuItem value="roteador">Roteador</MenuItem>
                                            <MenuItem value="servidor">Servidor</MenuItem>
                                            <MenuItem value="switch">Switch</MenuItem>
                                            <MenuItem value="olt">OLT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {condicoes.tipo_alteracao &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label-tipo-alteracao">Tipo de alteração &nbsp; &nbsp;  *</InputLabel>
                                        <Select
                                            value={form_data?.tipo_alteracao}
                                            defaultValue={'adicao'}
                                            labelId="select-label-tipo-alteracao"
                                            onChange={e => {
                                                handle_form_data("tipo_alteracao", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="selecione">Selecione</MenuItem>
                                            <MenuItem value="adicao">Adição</MenuItem>
                                            <MenuItem value="remocao">Remoção</MenuItem>
                                            <MenuItem value="substituicao">Substituição</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {condicoes.servico &&
                                <>
                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                if (e.target.value.length > 100) { return false }
                                                handle_form_data("servico", e.target.value)
                                            }}
                                            id="servico"
                                            label="Serviço"
                                            inputProps={{ placeholder: "Digite o código e/ou descrição do serviço que apresenta problema" }}
                                            required={true}
                                            value={form_data?.servico}
                                        />
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                            <InputLabel htmlFor="select-label-situacao-do-servico">Situação do serviço &nbsp; &nbsp;  *</InputLabel>
                                            <Select
                                                value={form_data?.situacao_do_servico}
                                                defaultValue={'instavel'}
                                                labelId="select-label-situacao-do-servico"
                                                onChange={e => {
                                                    handle_form_data("situacao_do_servico", e.target.value)
                                                }}
                                            >
                                                <MenuItem value="selecione">Selecione</MenuItem>
                                                <MenuItem value="instavel">Instável</MenuItem>
                                                <MenuItem value="indisponivel">Indisponível</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </>
                            }

                            {condicoes.ponto_a &&
                                <>
                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                if (e.target.value.length > 100) { return false }
                                                handle_form_data("ponto_a", e.target.value)
                                            }}
                                            id="ponto_a"
                                            label="Ponto A"
                                            inputProps={{ placeholder: "Digite a informação de local, pop, equipamento e porta de um lado do transporte" }}
                                            required={true}
                                            value={form_data?.ponto_a}
                                        />
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                if (e.target.value.length > 100) { return false }
                                                handle_form_data("ponto_b", e.target.value)
                                            }}
                                            id="ponto_b"
                                            label="Ponto B"
                                            inputProps={{ placeholder: "Digite a informação de local, pop, equipamento e porta do outro lado do transporte" }}
                                            required={true}
                                            value={form_data?.ponto_b}
                                        />
                                    </div>
                                </>
                            }

                            {condicoes.as_remoto &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("as_remoto", e.target.value)
                                        }}
                                        id="as_remoto"
                                        label="AS remoto"
                                        inputProps={{ placeholder: "Digite o código do AS" }}
                                        required={true}
                                        value={form_data?.as_remoto}
                                    />
                                </div>
                            }

                            {condicoes.asn_ou_prefixo &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("asn_ou_prefixo", e.target.value)
                                        }}
                                        id="asn_ou_prefixo"
                                        label="ASN/Prefixo"
                                        inputProps={{ placeholder: "Digite o código do ASN ou prefixo" }}
                                        required={true}
                                        value={form_data?.asn_ou_prefixo}
                                    />
                                </div>
                            }

                            {condicoes.cliente_as_ou_prefixo &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("cliente_as_ou_prefixo", e.target.value)
                                        }}
                                        id="cliente_as_ou_prefixo"
                                        label="Cliente AS/Prefixo"
                                        inputProps={{ placeholder: "Digite o código do AS ou prefixo do cliente" }}
                                        required={true}
                                        value={form_data?.cliente_as_ou_prefixo}
                                    />
                                </div>
                            }

                            {condicoes.usuario &&
                                <>
                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                if (e.target.value.length > 100) { return false }
                                                handle_form_data("usuario", e.target.value)
                                            }}
                                            id="usuario"
                                            label="Usuário"
                                            inputProps={{ placeholder: "Digite o usuário que será utilizado no acesso a VPN" }}
                                            required={true}
                                            value={form_data?.usuario}
                                        />
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                handle_form_data("senha", e.target.value)
                                            }}
                                            id="senha"
                                            label="Senha"
                                            inputProps={{ placeholder: "Digite a senha que será utilizada no acesso a VPN" }}
                                            required={true}
                                            value={form_data?.senha}
                                        />
                                    </div>
                                </>
                            }

                            {condicoes.localidade &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("localidade", e.target.value)
                                        }}
                                        id="localidade"
                                        label="Localidade"
                                        inputProps={{ placeholder: "Digite o nome da área, região, cidade, bairro ou pop" }}
                                        required={true}
                                        value={form_data?.localidade}
                                    />
                                </div>
                            }

                            {condicoes.descricao_local &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("descricao_local", e.target.value)
                                        }}
                                        id="descricao_local"
                                        label="Descrição do Local"
                                        inputProps={{ placeholder: "Digite uma descrição se o local dispõe de: outras fontes de acesso, computador, cabo console, sinal de dados móveis e etc" }}
                                        required={true}
                                        value={form_data?.descricao_local}
                                    />
                                </div>
                            }

                            {condicoes.ip &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("ip", e.target.value)
                                        }}
                                        id="ip"
                                        label="IP"
                                        inputProps={{ placeholder: "Digite o(s) endereço(s) IPv4 e/ou IPv6" }}
                                        required={true}
                                        value={form_data?.ip}
                                    />
                                </div>
                            }

                            {condicoes.ip_origem &&
                                <>
                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                if (e.target.value.length > 100) { return false }
                                                handle_form_data("ip_origem", e.target.value)
                                            }}
                                            id="ip_origem"
                                            label="IP de origem"
                                            inputProps={{ placeholder: "Digite o(s) endereço(s) IPv4 e/ou IPv6" }}
                                            required={true}
                                            value={form_data?.ip_origem}
                                        />
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                if (e.target.value.length > 100) { return false }
                                                handle_form_data("ip_destino", e.target.value)
                                            }}
                                            id="ip_destino"
                                            label="IP de destino"
                                            inputProps={{ placeholder: "Digite o(s) endereço(s) IPv4 e/ou IPv6" }}
                                            required={true}
                                            value={form_data?.ip_destino}
                                        />
                                    </div>
                                </>
                            }

                            {condicoes.ip_cdn &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("ip_cdn", e.target.value)
                                        }}
                                        id="ip_cdn"
                                        label="IP do CDN"
                                        inputProps={{ placeholder: "Digite o(s) endereço(s) IPv4 e/ou IPv6" }}
                                        required={true}
                                        value={form_data?.ip_cdn}
                                    />
                                </div>
                            }

                            {condicoes.vlan_e_ip &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("vlan_e_ip", e.target.value)
                                        }}
                                        id="vlan_e_ip"
                                        label="VLAN e IP"
                                        inputProps={{ placeholder: "Digite o(s) id da(a) VLAN(s) e endereço(s) IPv4 e/ou IPv6" }}
                                        required={true}
                                        value={form_data?.vlan_e_ip}
                                    />
                                </div>
                            }

                            {condicoes.quantidade_ips &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("quantidade_ips", e.target.value)
                                        }}
                                        id="quantidade_ips"
                                        label="Quantidade de IPs"
                                        inputProps={{ placeholder: "Digite a quantidade de ips ou a máscara de rede" }}
                                        required={true}
                                        value={form_data?.quantidade_ips}
                                    />
                                </div>
                            }

                            {condicoes.banda_contratada &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("banda_contratada", e.target.value)
                                        }}
                                        id="banda_contratada"
                                        label="Banda contratada"
                                        inputProps={{ placeholder: "Digite o valor da banda em Giga ou em Mega" }}
                                        required={true}
                                        value={form_data?.banda_contratada}
                                    />
                                </div>
                            }

                            {condicoes.nova_banda &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 100) { return false }
                                            handle_form_data("nova_banda", e.target.value)
                                        }}
                                        id="nova_banda"
                                        label="Nova banda"
                                        inputProps={{ placeholder: "Digite o valor da banda em Giga ou em Mega" }}
                                        required={true}
                                        value={form_data?.nova_banda}
                                    />
                                </div>
                            }

                            {condicoes.designador &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("designador", e.target.value)
                                        }}
                                        id="designador"
                                        label="Designador"
                                        inputProps={{ placeholder: "Digite a referência do tipo de serviço" }}
                                        required={true}
                                        value={form_data?.designador}
                                    />
                                </div>
                            }

                            {condicoes.fabricante_e_modelo &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("fabricante_e_modelo", e.target.value)
                                        }}
                                        id="fabricante_e_modelo"
                                        label="Fabricante e modelo"
                                        inputProps={{ placeholder: "Digite o nome do fabricante e código do modelo do equipamento" }}
                                        required={true}
                                        value={form_data?.fabricante_e_modelo}
                                    />
                                </div>
                            }

                            {condicoes.tracerouter &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        multiline
                                        onChange={e => {
                                            handle_form_data("tracerouter", e.target.value)
                                        }}
                                        id="traceroute"
                                        label="Traceroute"
                                        inputProps={{ placeholder: "Digite o resultado do comando de tracert/traceroute para o destino" }}
                                        required={true}
                                        value={form_data?.tracerouter}
                                    />
                                </div>
                            }

                            {condicoes.url &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_form_data("url", e.target.value)
                                        }}
                                        id="url"
                                        label="URL"
                                        inputProps={{ placeholder: "Digite o endereço web do serviço/servidor" }}
                                        required={true}
                                        value={form_data?.url}
                                    />
                                </div>
                            }

                            {condicoes.info_cliente_1 &&
                                <>
                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                handle_form_data("info_cliente_1", e.target.value)
                                            }}
                                            id="info_cliente_1"
                                            label="Informações cliente 1"
                                            inputProps={{ placeholder: "Digite os dados de um cliente que está passando pela situação: Nome, usuário, código, endereço ip" }}
                                            required={true}
                                            value={form_data?.info_cliente_1}
                                        />
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <TextField
                                            className={styles.form_input}
                                            onChange={e => {
                                                handle_form_data("info_cliente_2", e.target.value)
                                            }}
                                            id="info_cliente_2"
                                            label="Informações cliente 2"
                                            inputProps={{ placeholder: "Digite os dados de um cliente que está passando pela situação: Nome, usuário, código, endereço ip" }}
                                            value={form_data?.info_cliente_2}
                                        />
                                    </div>
                                </>
                            }

                            {condicoes.titulo &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 200) { return false }
                                            handle_form_data("titulo", e.target.value)
                                        }}
                                        id="titulo"
                                        label="Título"
                                        inputProps={{ placeholder: "Digite uma descrição resumida da solicitação" }}
                                        required={true} value={form_data?.titulo}
                                    />
                                </div>
                            }

                            {condicoes.detalhes_solicitacao &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        multiline
                                        rows={10}
                                        onChange={e => {
                                            handle_form_data("detalhes_solicitacao", e.target.value)
                                        }}
                                        id="detalhes_solicitacao"
                                        label="Detalhes da solicitação"
                                        inputProps={{ placeholder: "Digite uma descrição completa e detalhada da solicitação" }}
                                        required={true}
                                        value={form_data?.detalhes_solicitacao}
                                    />
                                </div>
                            }




                            <div style={{ width: "100%", height: 80, display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px #888888 solid' }}>
                                <div style={{
                                    color: "#777777",
                                    marginLeft: 0,
                                    marginTop: 15,
                                    marginBottom: 5,
                                    top: 10,
                                    fontSize: '1em'
                                }}>O evento está causando indisponibilidade para o provedor ou para algum cliente do provedor?: </div>
                                <Checkbox
                                    style={{ marginTop: 10, marginLeft: -10 }}
                                    checked={form_data.indisponibilidade === 'Sim' ? true : false}
                                    onChange={e => {
                                        handle_form_data("indisponibilidade", e.target.checked ? 'Sim' : 'Não')
                                    }}
                                    name="checkedi"
                                    color="primary"
                                /> <span style={{ marginLeft: 0, marginTop: 10, fontWeight: 'bold' }}>{form_data.indisponibilidade === 'Sim' ? form_data.indisponibilidade.toUpperCase() : ''}</span>
                            </div>



                            {condicoes.anexo &&
                                <div style={{ width: "100%" }}>
                                    <div style={{ margin: 0, border: '1px #c7c7c7 solid', marginTop: 2, minHeight: 50, borderRadius: 3, overflow: 'hidden', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                        <FileDropzone set_files={set_anexo} />
                                    </div>
                                </div>
                            }


                            {/* ***************************************************** */}
                                {/* {
                                    confirmAlert({
                                        customUI: ({ onClose }) => {
                                            return (
                                                <div>
                                                    <p style={{ fontSize: '1.4em', margin: 5 }}>Dados inseridos com sucesso!</p>
                                                    <p style={{ fontSize: '1.2em', margin: 5 }}><b>ID: </b>0000</p>
                                                    <p style={{ fontSize: '1.2em', margin: 5 }}><b>Título: </b>TITULO</p>
                                                    <p style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 5, marginTop: 15, marginBottom: 5 }}><b>Deseja abrir um novo chamado?</b></p>
                                                    <button style={{ fontSize: '1.1em', margin: 3, padding: 5 }} onClick={() => {
                                                        zero_form()
                                                        onClose();
                                                    }}>Sim</button>

                                                    <button style={{ fontSize: '1.1em', marginLeft: 10, marginRight: 5, marginTop: 5, marginBottom: 5, padding: 5 }} onClick={() => {
                                                        
                                                    }}>Não</button>
                                                </div>
                                            );
                                        }
                                    })
                                } */}
                            {/* ***************************************************** */}

                            <div style={{ width: "100%" }}>
                                <Button
                                    className={styles.btn_submit}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={show_toast}
                                    onClick={async () => {
                                        set_content_toast(() => (
                                            <div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '100%', height: 70, maxHeight: 70 }}>
                                                <div className="loader" style={{ width: 40, height: 40, marginRight: 10 }}></div>
                                                <h3>Aguarde, enviando dados...</h3>
                                            </div>
                                        ))
                                        set_show_toast(true)

                                        // const filters = {
                                        //     descricao: "Descrição",
                                        //     senha: "Senha",
                                        //     usuario: "Usuário"
                                        // }

                                        // for (let f in Object.values(filters)) {
                                        //     let filter = Object.keys(filters)[f]
                                        //     if (form_data[filter] === undefined || form_data[filter] === '') {
                                        //         return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                        //     }
                                        // }

                                        if (loggedUser.nivel === 'Cliente-Admin' && loggedUser.conta_master.masterUserId === undefined) {
                                            set_show_toast(false)
                                            return alert('A configuração de seu usuário foi alterada, por favor faça logoff, e login novamente para atualizar os dados de sua conta')
                                        }

                                        const data_to_send = {
                                            customer_id: loggedUser.nivel.match(/Super|Técnico/i) ? requerente.id : loggedUser.nivel === 'Cliente-Admin' ? loggedUser.conta_master.masterUserId : loggedUser.id,
                                            customer_name: loggedUser.nivel.match(/Super|Técnico/i) ? requerente.nome : loggedUser.nivel === 'Cliente-Admin' ? loggedUser.conta_master.masterUserName : loggedUser.nome,
                                            situacao: { titulo: 'novo', descricao: '' },
                                            indisponibilidade: form_data.indisponibilidade
                                        }


                                        // return console.log(data_to_send)//breack point for DEBUG

                                        let campo_obr = []
                                        // console.log('Condiçoes: ', condicoes)
                                        for (let f in Object.values(condicoes)) {
                                            let filter = Object.keys(form_data)[f]
                                            // console.log(filter)

                                            if (condicoes[filter]) {
                                                if (filter !== 'info_cliente_2' && filter !== 'reincidente' && filter !== 'anexo' && (form_data[filter] === undefined || form_data[filter] === '' || form_data[filter] === null || form_data[filter] === 'selecione')) {
                                                    // console.log('pegou um campo', filter)
                                                    campo_obr.push(form_labels[filter])
                                                } else {
                                                    data_to_send[filter] = form_data[filter]
                                                }
                                            }
                                        }

                                        if (campo_obr.length > 0) {
                                            if (campo_obr.length === 1) {
                                                set_show_toast(false)
                                                return alert(`O campo ${campo_obr[0].toUpperCase()}, é obrigatório`)
                                            } else {
                                                set_show_toast(false)
                                                return alert(`Os campos ${campo_obr.join(', ').toUpperCase()}, são obrigatórios`)
                                            }
                                        }

                                        // console.log('dados para envio: ', data_to_send)

                                        try {
                                            let resp = null
                                            if (anexo && anexo.length > 0) {
                                                // console.log(anexo)
                                                const data_FormData = new FormData();
                                                // data_FormData.append('file', anexo)
                                                for (let a in anexo) {
                                                    // console.log(anexo[a])
                                                    data_FormData.append('file', anexo[a])
                                                }
                                                // console.log(anexo)
                                                for (let key in data_to_send) {
                                                    data_FormData.append(`${key}`, data_to_send[key])
                                                    // console.log(key, data_to_send[key])
                                                }

                                                resp = await api_bd.post('/tickets', data_FormData, {
                                                    headers: {
                                                        "Content-Type": `multipart/form-data; boundary=${data_FormData._boundary}`,
                                                    }
                                                })
                                                set_show_toast(false)
                                            } else {
                                                delete data_to_send.anexo
                                                // console.log('ANEXO::: ', anexo)
                                                // console.log('DATA_TO_SEND::: ', data_to_send)
                                                resp = await api_bd.post('/tickets', data_to_send)
                                                set_show_toast(false)
                                            }
                                            // data_client_update(resp.data)

                                            confirmAlert({
                                                customUI: ({ onClose }) => {
                                                    return (
                                                        <div>
                                                            <p style={{ fontSize: '1.4em', margin: 5 }}>Dados inseridos com sucesso!</p>
                                                            <p style={{ fontSize: '1.2em', margin: 5 }}><b>ID: </b>{resp.data.cod_id < 10 ? '000' + resp.data.cod_id : resp.data.cod_id < 100 ? '00' + resp.data.cod_id : resp.data.cod_id < 1000 ? '0' + resp.data.cod_id : resp.data.cod_id}</p>
                                                            <p style={{ fontSize: '1.2em', margin: 5 }}><b>Título: </b>{data_to_send.titulo}</p>
                                                            <p style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 5, marginTop: 15, marginBottom: 5 }}><b>Deseja abrir um novo chamado?</b></p>
                                                            <button style={{ fontSize: '1.1em', margin: 3, padding: 5 }} onClick={() => {
                                                                zero_form()
                                                                onClose();
                                                            }}>Sim</button>

                                                            <button style={{ fontSize: '1.1em', marginLeft: 10, marginRight: 5, marginTop: 5, marginBottom: 5, padding: 5 }} onClick={() => {
                                                                zero_form()
                                                                onClose();
                                                                set_navigation_state({ page_content: 'listtickets' })
                                                            }}>Não</button>
                                                        </div>
                                                    );
                                                }
                                            });

                                            // set_content_toast(()=>(
                                            //     <div>
                                            //         <p style={{ fontSize: '1.4em', margin: 5 }}>Dados inseridos com sucesso!</p>
                                            //         <p style={{ fontSize: '1.2em', margin: 5 }}><b>ID: </b>{resp.data.cod_id < 10 ? '000' + resp.data.cod_id : resp.data.cod_id < 100 ? '00' + resp.data.cod_id : resp.data.cod_id < 1000 ? '0' + resp.data.cod_id : resp.data.cod_id}</p>
                                            //         <p style={{ fontSize: '1.2em', margin: 5 }}><b>Título: </b>{data_to_send.titulo}</p>
                                            //         <p style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 5, marginTop: 15, marginBottom: 5 }}><b>Deseja abrir um novo chamado?</b></p>
                                            //         <button style={{ fontSize: '1.1em', margin: 3, padding: 5 }} onClick={() => {
                                            //             zero_form()
                                            //             set_show_toast(false)
                                            //             // onClose();
                                            //         }}>Sim</button>

                                            //         <button style={{ fontSize: '1.1em', marginLeft: 10, marginRight: 5, marginTop: 5, marginBottom: 5, padding: 5 }} onClick={() => {
                                            //             zero_form()
                                            //             set_navigation_state({ page_content: 'listtickets' })
                                            //             set_show_toast(false)
                                            //             // onClose();
                                            //         }}>Não</button>
                                            //     </div>
                                            // ))
                                            // set_show_toast(true)

                                        } catch (error) {
                                            if (error.message.indexOf('code 400') > -1) {
                                                alert("Verifique os dados e tente novamente.")
                                            }
                                        }
                                    }}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div >
    );
}

export default FormDataTickets;